import React from 'react';

function ContentElements({ createElement }) {
  return (
    <div className='element-options-container'>
      <p className='title'>Content Elements</p>
      <button id='new-element-button' onClick={createElement}>+</button>
    </div>
  );
}

export default ContentElements;
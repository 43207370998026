import React, { useEffect } from 'react';

function PropertiesContainer({ currentTextField, handleInputChange }) {
  if (!currentTextField) return null;

  const properties = ['name', 'x', 'y', 'width', 'height'];
  useEffect(() => {
    properties.forEach((property) => {
      const input = document.getElementById(`${property}-property`);
      if (input) {
        input.value = currentTextField[property];
      }
    });
  }, [currentTextField]);

  return (
    <div className='properties-container scrollable-container'>
      <p className='title'>Properties</p>
      {properties.map((property, index) => (
        <div className='property-container' key={index}>
          <label htmlFor={`${property}-property`}>{property.charAt(0).toUpperCase() + property.slice(1)}:</label>
          <input
            className='text-input'
            type={property === 'name' ? 'text' : 'number'}
            id={`${property}-property`}
            onChange={(e) => handleInputChange(property, e)}
          />
        </div>
      ))}
    </div>
  );
}

export default PropertiesContainer;
import { nanoid } from 'nanoid';

class TextField {
  constructor(id, name, x, y, width, height) {
    this.name = name || 'New';
    this.x = x || 0;
    this.y = y || 0;
    this.width = width || 200;
    this.height = height || 50;
    this._id = id || nanoid();
  }

  getSaveObject() {
    return {
      name: this.name,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      _id: this._id
    };
  }
}

export default TextField;
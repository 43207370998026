import React from 'react';

function ElementList({ activeTemplate, activeElement, setActiveElement, moveElement, removeElement }) {
  return (
    <div className='element-list-container scrollable-container'>
      {activeTemplate && activeTemplate.dd.content.map((element) => (
        <div
          className={`element-container ${activeElement && activeElement.id === element.id ? 'active' : ''}`}
          key={element.id}
          onClick={() => setActiveElement(element)}
        >
          <p>
            <strong>Text:</strong>
            {` ${element.text.slice(0, 10)}`}...
          </p>
          <div className='buttons-container'>
            <button onClick={() => moveElement(element.id, 'up')}>&uarr;</button>
            <button onClick={() => moveElement(element.id, 'down')}>&darr;</button>
            <button onClick={() => removeElement(element.id)}>X</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ElementList;
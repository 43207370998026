function deleteTemplate(id, password) {
  return new Promise((resolve, reject) => {
    fetch(`https://pdf-monkey-backend.herokuapp.com/pdfBuilder/${id}?password=${password}`, {
      method: 'DELETE'
    })
      .then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default deleteTemplate;
import React from 'react';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { AuthContext } from '../Contexts/AuthContext';

function Header({ setActivePage }) {
  const { isLoggedIn } = React.useContext(AuthContext);

  const switchPage = (page) => {
    if (isLoggedIn) {
      setActivePage(page);
    } else {
      setActivePage('HomePage');
    }
  }

  return (
    <Navbar expand='md'>
      <Navbar.Brand onClick={() => switchPage('HomePage')}>
        PDF Monkey <small>...ooh ooh ah ah</small>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav' className='navbar-dropdown'>
        <Nav className='ml-auto'>
          <NavDropdown title="Editor" id="basic-nav-dropdown" className='navbar-dropdown'>
            <NavDropdown.Item onClick={() => switchPage('PdfBuilderPage')}>
              PDF Builder
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => switchPage('PdfFillerPage')}>
              PDF Filler
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );   
}

export default Header;
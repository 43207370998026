import React, { useState, useEffect, useContext, useCallback } from 'react';
import getTemplateList from '../../../Requests/PdfBuilder/GetTemplateList';
import PdfMakeTemplate from '../../../Classes/PdfBuilder/PdfMakeTemplateClass';
import PdfMakeText from '../../../Classes/PdfBuilder/PdfMakeTextClass';
import PdfViewer from '../../PdfViewer';
import { AuthContext } from '../../../Contexts/AuthContext';
import TemplateOptions from './TemplateOptions';
import ContentElements from './ContentElements';
import ElementList from './ElementList';
import PropertiesContainer from './PropertiesContainer';
import updateTemplate from '../../../Requests/PdfBuilder/UpdateTemplate';
import deleteTemplate from '../../../Requests/PdfBuilder/DeleteTemplate';
import createTemplate from '../../../Requests/PdfBuilder/CreateTemplate';

function PdfMakeEditorPage() {
  const [activeTemplate, setActiveTemplate] = useState(new PdfMakeTemplate());
  const [activeElement, setActiveElement] = useState(null);
  const { password } = useContext(AuthContext);
  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    async function fetchTemplates() {
      const templates = await getTemplateList(password);
      const processedTemplates = templates.map((template) => {
        const content = template.dd.content.map((element) => new PdfMakeText(element.text || '', element.style || '', element.fontSize || 12, element.bold || false, element.italics || false, element.alignment || 'left', element.margin || [0, 0, 0, 0]));
        return new PdfMakeTemplate(template.name, template.dd.background, content, template.dd.footer, template.dd.styles, template.dd.images, template._id);
      });
      setTemplateList(processedTemplates);
    }
    fetchTemplates();
  }, [password]);

  const newTemplate = useCallback(() => {
    setActiveTemplate(new PdfMakeTemplate());
    setActiveElement(null);
  }, []);

  const createElement = useCallback(() => {
    const element = new PdfMakeText();
    setActiveElement(element);
    setActiveTemplate((prevTemplate) => {
      const updatedTemplate = new PdfMakeTemplate();
      Object.assign(updatedTemplate, prevTemplate);
      updatedTemplate.addContentElement(element);
      return updatedTemplate;
    });
  }, []);

  const handleInputChange = useCallback((type, event) => {
    let updatedValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setActiveElement((prevElement) => {
      const newElement = new PdfMakeText();
      Object.assign(newElement, prevElement, { [type]: updatedValue });
      setActiveTemplate((prevTemplate) => {
        const updatedTemplate = new PdfMakeTemplate();
        Object.assign(updatedTemplate, prevTemplate);
        updatedTemplate.updateElement(newElement.id, newElement);
        return updatedTemplate;
      });
      return newElement;
    });
  }, []);

  const handleMarginInputChange = useCallback((index, event) => {
    const updatedValue = parseFloat(event.target.value) || 0;
    setActiveElement((prevElement) => {
      const newElement = new PdfMakeText();
      const updatedMargin = [...prevElement.margin];
      updatedMargin[index] = updatedValue;
      Object.assign(newElement, prevElement, { margin: updatedMargin });
      setActiveTemplate((prevTemplate) => {
        const updatedTemplate = new PdfMakeTemplate();
        Object.assign(updatedTemplate, prevTemplate);
        updatedTemplate.updateElement(newElement.id, newElement);
        return updatedTemplate;
      });
      return newElement;
    });
  }, []);

  const updateActiveTemplate = useCallback((e) => {
    if (e.target.value === 'none') {
      setActiveTemplate(new PdfMakeTemplate());
    } else {
      const template = templateList.find(template => template._id === e.target.value);
      setActiveTemplate(template);
    }
    setActiveElement(null);
  }, [templateList]);

  const removeElement = useCallback((elementId) => {
    setActiveTemplate((prevTemplate) => {
      const updatedTemplate = new PdfMakeTemplate();
      Object.assign(updatedTemplate, prevTemplate);
      updatedTemplate.removeElement(elementId);
      return updatedTemplate;
    });
    setActiveElement((prevElement) => {
      if (prevElement && prevElement.id === elementId) return null;
      return prevElement;
    });
  }, []);

  const moveElement = useCallback((elementId, direction) => {
    setActiveTemplate((prevTemplate) => {
      const updatedTemplate = new PdfMakeTemplate();
      Object.assign(updatedTemplate, prevTemplate);
      if (direction === 'up') {
        updatedTemplate.moveContentElementUp(elementId);
      } else {
        updatedTemplate.moveContentElementDown(elementId);
      }
      return updatedTemplate;
    });
  }, []);

  const updateTemplateName = useCallback((e) => {
    setActiveTemplate((prevTemplate) => {
      const updatedTemplate = new PdfMakeTemplate();
      Object.assign(updatedTemplate, prevTemplate, { name: e.target.value });
      return updatedTemplate;
    });
  }, []);

  const saveTemplate = useCallback(async () => {
    const existingTemplate = templateList.find(
      (template) => template._id === activeTemplate._id
    );
    if (existingTemplate) {
      const updated = await updateTemplate(activeTemplate, password);
      if (updated) {
        setTemplateList((prevTemplates) =>
          prevTemplates.map((template) =>
            template._id === activeTemplate._id ? activeTemplate : template
          )
        );
        alert('Template updated!')
      } else {
        console.log('Error updating template');
      }
    } else {
      const created = await createTemplate(activeTemplate, password);
      if (created) {
        setTemplateList((prevTemplates) => [...prevTemplates, activeTemplate]);
        alert('Template created!')
      } else {
        console.log('Error creating template');
      }
    }
  }, [activeTemplate, password, templateList]);

  const removeTemplate = useCallback(async () => {
    const success = await deleteTemplate(activeTemplate._id, password);
    if (success) {
      setTemplateList((prevTemplates) =>
        prevTemplates.filter((template) => template._id !== activeTemplate._id)
      );
      newTemplate();
      alert('Template deleted!')
    } else {
      console.log("Error deleting template");
    }
  }, [activeTemplate, newTemplate, password]);  

  return (
    <div className='editor-container'>
      <div className='elements-container'>
        <TemplateOptions
          templateList={templateList}
          updateActiveTemplate={updateActiveTemplate}
          newTemplate={newTemplate}
          activeTemplate={activeTemplate}
          updateTemplateName={updateTemplateName}
          saveTemplate={saveTemplate}
          removeTemplate={removeTemplate}
        />
        <ContentElements createElement={createElement} />
        <ElementList
          activeTemplate={activeTemplate}
          activeElement={activeElement}
          setActiveElement={setActiveElement}
          moveElement={moveElement}
          removeElement={removeElement}
        />
      </div>
      <PdfViewer pdfDocument={activeTemplate && activeTemplate.getPdfDocument()} />
      <PropertiesContainer
        activeElement={activeElement}
        handleInputChange={handleInputChange}
        handleMarginInputChange={handleMarginInputChange}
      />
    </div>
  );
}

export default PdfMakeEditorPage;
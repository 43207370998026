import { nanoid } from 'nanoid';

class PdfMakeText {
  constructor(text, style, fontSize, bold, italics, alignment, margin, index) {
    this.text = text || '';
    this.style = style || '';
    this.fontSize = fontSize || 12;
    this.bold = bold || false;
    this.italics = italics || false;
    this.alignment = alignment || 'left';
    this.margin = margin || [0, 0, 0, 0];
    this.id = nanoid();
    this.index = index || 0;
  }

  getObject() {
    return {
      text: this.text,
      style: this.style,
      fontSize: this.fontSize,
      bold: this.bold,
      italics: this.italics,
      alignment: this.alignment,
      margin: this.margin
    };
  }
}

export default PdfMakeText;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { nanoid } from 'nanoid';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PdfMakeTemplate {
  constructor(name, background, content, footer, styles, images, id) {
    this.name = name || '';
    this.dd = {
      background: background || [],
      content: content || [],
      footer: footer || {},
      styles: styles || {},
      images: images || {}
    };
    this._id = id || nanoid();
  }

  addContentElement(element) {
    element.index = this.dd.content.length;
    this.dd.content.push(element);
  }

  updateElement(id, element) {
    this.dd.content = this.dd.content.map((item) => {
      if (item.id === id) return element;
      return item;
    });
  }

  moveContentElementUp(id) {
    const index = this.dd.content.findIndex(item => item.id === id);
    if (index <= 0 || index >= this.dd.content.length) return;
    const temp = this.dd.content[index - 1];
    this.dd.content[index - 1] = this.dd.content[index];
    this.dd.content[index] = temp;
  }

  moveContentElementDown(id) {
    const index = this.dd.content.findIndex(item => item.id === id);
    if (index < 0 || index >= this.dd.content.length - 1) return;
    const temp = this.dd.content[index + 1];
    this.dd.content[index + 1] = this.dd.content[index];
    this.dd.content[index] = temp;
  }
  
  removeElement(id) {
    this.dd.content = this.dd.content.filter((item) => item.id !== id);
  }

  getObject() {
    return {
      background: this.dd.background,
      content: this.dd.content.map((item) => item.getObject()),
      footer: this.dd.footer,
      styles: this.dd.styles,
      images: this.dd.images
    };
  }

  getSaveObject() {
    return {
      name: this.name,
      dd: this.getObject(),
      _id: this._id
    };
  }

  getPdfDocument() {
    return pdfMake.createPdf(this.getObject());
  }
}

export default PdfMakeTemplate;
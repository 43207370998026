function createTemplate(template, password) {
  console.log('in createTemplate')
  return new Promise((resolve, reject) => {
    fetch(`https://pdf-monkey-backend.herokuapp.com/pdfBuilder?password=${password}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(template.getSaveObject())
    })
      .then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default createTemplate;
import React from 'react';

function TextFieldList({ currentPdf, currentTextField, setCurrentTextField, removeTextField, textFieldList }) {
  
  return (
    <div className='element-list-container scrollable-container'>
      {currentPdf && textFieldList.map((element) => (
        <div
          className={`element-container ${currentTextField && currentTextField._id === element._id ? 'active' : ''}`}
          key={element._id}
          onClick={() => {
              setCurrentTextField(null);
              setCurrentTextField(element)
            }
          }
        >
          <p>
            <strong>Field:</strong>
            {` ${element.name}`}...
          </p>
          <div className='buttons-container'>
            <button onClick={() => removeTextField(element)}>X</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TextFieldList;
function checkPassword(password) {
  return new Promise((resolve, reject) => {
    fetch(`https://pdf-monkey-backend.herokuapp.com/check-password?password=${password}`, {
      method: 'GET'
    })
      .then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export default checkPassword;
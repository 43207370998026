import React, { useState } from 'react';
import { AuthContext } from './Contexts/AuthContext';
import Header from './Components/Header';
import LoginPage from './Components/Pages/LoginPage';
import PdfBuilderPage from './Components/Pages/PdfBuilder/PdfBuilderPage';
import PdfFillerPage from './Components/Pages/PdfFiller/PdfFillerPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [password, setPassword] = useState('');

  const [activePage, setActivePage] = useState('LoginPage');
  const renderPage = () => {
    switch (activePage) {
      case 'LoginPage':
        return <LoginPage />;
      case 'PdfBuilderPage':
        return <PdfBuilderPage />;
      case 'PdfFillerPage':
        return <PdfFillerPage />;
      default:
        return <LoginPage />;
    }
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, password, setPassword }}>
      <div id="content">
        <Header setActivePage={setActivePage} />
        {renderPage()}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
import React from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import checkPassword from "../../Requests/CheckPassword";
import SuccessIcon from "../SuccessIcon";

function LoginPage() {
  const { isLoggedIn, setIsLoggedIn, password, setPassword } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!isLoggedIn) {
      const storedPassword = localStorage.getItem('password');
      if (storedPassword) {
        setPassword(storedPassword);
        checkPassword(storedPassword)
          .then((isCorrect) => {
            setIsLoggedIn(isCorrect);
            if (isCorrect) {
              localStorage.setItem('password', storedPassword);
            }
          });
      }
    }
  }, [setPassword, setIsLoggedIn]);

  const handlePasswordChange = (event) => {
    event.preventDefault();
    checkPassword(password)
      .then((isCorrect) => {
        setIsLoggedIn(isCorrect);
        if (isCorrect) {
          localStorage.setItem('password', password);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="page-container">
      <h2 className="page-title">Welcome to PDF Monkey!</h2>
      <p>Please enter the password to enter this site...</p>
      <div className="password-container">
        <div className="input-container">
          <label id="input-field-label" htmlFor="input-field">Password:</label>
          <input id="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button id="submit-button" onClick={handlePasswordChange}>Submit</button>
      </div>
      {isLoggedIn !== null && (isLoggedIn ? <SuccessIcon /> : <p>Login failed!</p>)}
    </div>
  );
}

export default LoginPage;
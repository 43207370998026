import React from 'react';

function TextFields({ createTextField }) { 
  return (
    <div className='element-options-container'>
      <p className='title'>Text Fields</p>
      <button id='new-element-button' onClick={createTextField}>+</button>
    </div>
  );
}

export default TextFields;
import React from 'react';

function PropertiesContainer({ activeElement, handleInputChange, handleMarginInputChange }) {
  if (!activeElement) return null;
  return (
    <div className='properties-container scrollable-container'>
      <p className='title'>Properties</p>
      {['text', 'fontSize', 'bold', 'italics', 'alignment'].map((property, index) => (
        <div className='property-container' key={index}>
          <label htmlFor={`${property}-property`}>{property.charAt(0).toUpperCase() + property.slice(1)}:</label>
          <input
            className={property === 'bold' || property === 'italics' ? 'checkbox-input' : 'text-input'}
            type={property === 'bold' || property === 'italics' ? 'checkbox' : property === 'fontSize' ? 'number' : 'text'}
            id={`${property}-property`}
            value={activeElement[property] || ''}
            checked={property === 'bold' || property === 'italics' ? activeElement[property] || false : undefined}
            onChange={(e) => handleInputChange(property, e)}
          />
        </div>
      ))}
      <div className='property-container'>
        <label htmlFor='margin-property'>Margin:</label>
        {activeElement.margin.map((value, index) => (
          <div className="margin-input-container" key={index}>
            <label htmlFor={`margin-property-${index}`}>{index === 0 ? 'Left' : index === 1 ? 'Top' : index === 2 ? 'Right' : 'Bottom'}:</label>
            <input className='text-input'
              type='number'
              id={`margin-property-${index}`}
              value={activeElement.margin[index] || 0}
              onChange={(e) => handleMarginInputChange(index, e)}
              key={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PropertiesContainer;
import React, { useEffect } from 'react';
import PdfLib from '../Classes/PdfFiller/PdfClass'

function PdfViewer(props) {
  // const [currentPdf, setCurrentPdf] = React.useState(null);
  const [pdfSource, setPdfSource] = React.useState(null);

  useEffect(() => {
    if (props.file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Data = e.target.result.split(',')[1];
        const pdfLib = new PdfLib(props.file.name, base64Data);
        // setCurrentPdf(pdfLib);
        const source = await pdfLib.getPDFAsSource();
        setPdfSource(source);
      };
      reader.readAsDataURL(props.file);
    } else if (props.pdfDocument) {
      props.pdfDocument.getDataUrl((dataUrl) => {
        setPdfSource(dataUrl);
      });
    } else {
      setPdfSource(props.source);
    }
  }, [props.source, props.pdfDocument, props.file]);

  return (
    <div className="pdf-container">
      {pdfSource && (
        <iframe src={pdfSource} title="pdf-preview" id="pdf-preview"/>
      )}
    </div>
  );
}

export default PdfViewer;
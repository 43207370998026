import React, { useEffect, useCallback, useContext } from 'react';
import PdfViewer from '../../PdfViewer';
import TemplateOptions from './TemplateOptions';
import TextFields from './TextFields';
import TextFieldList from './TextFieldList';
import PropertiesContainer from './PropertiesContainer';
import PdfLib from '../../../Classes/PdfFiller/PdfClass';
import TextField from '../../../Classes/PdfFiller/TextFieldClass';
import { AuthContext } from '../../../Contexts/AuthContext';
import getPdfList from '../../../Requests/PdfFiller/GetPdfList';
import createPdf from '../../../Requests/PdfFiller/CreatePdf';
import updatePdf from '../../../Requests/PdfFiller/UpdatePdf';

function PdfFillerPage() {
  const [currentPdf, setCurrentPdf] = React.useState(new PdfLib('New'));
  const [textFieldList, setTextFieldList] = React.useState(currentPdf.textFields);
  const [currentTextField, setCurrentTextField] = React.useState(null);
  const password = useContext(AuthContext).password;
  const [pdfList, setPdfList] = React.useState([]);

  useEffect(() => {
    async function fetchPdfs() {
      const pdfs = await getPdfList(password);
      const processedPdfs = pdfs.map((pdf) => {
        const textFields = pdf.textFields.map((textField) => {
          return new TextField(textField._id, textField.name, textField.x, textField.y, textField.width, textField.height);
        });
        return new PdfLib(pdf.name, pdf.base64, pdf._id, textFields);
      });
      setPdfList(processedPdfs);
    }
    fetchPdfs();
  }, []);

  const uploadPdf = async (base64) => {
    const newPdf = new PdfLib();
    newPdf.base64 = base64;
    setTextFieldList(await newPdf.deleteAllTextFields());
    setCurrentPdf(newPdf);
  };

  const updatePdfName = useCallback((e) => {
    setCurrentPdf((prevPdf) => {
      const updatedPdf = new PdfLib();
      Object.assign(updatedPdf, prevPdf, { name: e.target.value });
      return updatedPdf;
    });
  }, []);

  const savePdf = useCallback(async () => {
    const existingPdf = pdfList.find(
      (pdf) => pdf._id === currentPdf._id
    );
    if (existingPdf) {
      const updated = await updatePdf(currentPdf, password);
      if (updated) {
        setPdfList(([prevPdfs]) =>
          [prevPdfs].map((pdf) =>
            pdf._id === currentPdf._id ? currentPdf : pdf
          )
        );
        alert('Pdf updated!')
      } else {
        console.log('Error updating pdf');
      }
    } else {
      const created = await createPdf(currentPdf, password);
      if (created) {
        setPdfList(([prevPdfs]) => [...[prevPdfs], currentPdf]);
        alert('Pdf created!')
      } else {
        console.log('Error creating pdf');
      }
    }
  }, [currentPdf, password, pdfList]);

  const updateCurrentPdf = useCallback(async (e) => {
    if (e.target.value === 'none') {
      const newPdf = new PdfLib();
      setCurrentPdf(newPdf);
      setTextFieldList(await newPdf.getTextFields());
      setCurrentTextField(null);
    } else {
      const pdf = pdfList.find(pdf => pdf._id === e.target.value);
      setCurrentPdf(pdf);
      setTextFieldList(await pdf.getTextFields());
      setCurrentTextField(null);
    }
  }, [pdfList]);

  const removeTextField = useCallback(async (field) => {
    const updatedTextFields = await currentPdf.deleteTextField(field);
    setTextFieldList(updatedTextFields);
    setCurrentPdf(prevPdf => {
      const updatedPdf = new PdfLib();
      Object.assign(updatedPdf, prevPdf, { textFields: updatedTextFields });
      return updatedPdf;
    });
    setCurrentTextField(null);
  }, [currentPdf]);

  const createTextField = useCallback(async () => {
    const updatedTextFields = await currentPdf.createTextField(new TextField());
    setTextFieldList(updatedTextFields);
    setCurrentPdf(prevPdf => {
      const updatedPdf = new PdfLib();
      Object.assign(updatedPdf, prevPdf, { textFields: updatedTextFields });
      return updatedPdf;
    });
  }, [currentPdf]);

  const handleInputChange = useCallback(
    async (type, event) => {
      let updatedValue = event.target.value;
      if (updatedValue === '') {
        updatedValue = ' ';
      } else if (!isNaN(updatedValue)) {
        updatedValue = parseFloat(updatedValue);
      }
      const updatedTextField = new TextField(currentTextField._id);
      Object.assign(updatedTextField, currentTextField, { [type]: updatedValue });
      const updatedTextFields = await currentPdf.updateTextField(updatedTextField);
      setCurrentPdf((prevPdf) => {
        const updatedPdf = new PdfLib(prevPdf.name, prevPdf.base64, prevPdf._id);
        Object.assign(updatedPdf, prevPdf, { textFields: updatedTextFields });
        return updatedPdf;
      });
      setTextFieldList(updatedTextFields);
      setCurrentTextField(updatedTextField);
    },
    [currentPdf, currentTextField]
  );  

  return (
    <div className='editor-container'>
      {console.log(currentPdf._id)}
      <div className='elements-container'>
        <TemplateOptions
          pdfList={pdfList}
          currentPdf={currentPdf}
          uploadPdf={uploadPdf}
          updatePdfName={updatePdfName}
          savePdf={savePdf}
          updateCurrentPdf={updateCurrentPdf}
        />
        <TextFields
          createTextField={createTextField}
        />
        <TextFieldList
          currentPdf={currentPdf}
          currentTextField={currentTextField}
          setCurrentTextField={setCurrentTextField}
          removeTextField={removeTextField}
          textFieldList={textFieldList}
          setTextFieldList={setTextFieldList}
        />
      </div>
      <PdfViewer source={currentPdf && currentPdf.base64} />
      <PropertiesContainer
        currentTextField={currentTextField}
        handleInputChange={handleInputChange}
      />
    </div>
  );
}

export default PdfFillerPage;
import React, { useState, useEffect } from 'react';

function TemplateOptions({ templateList, updateActiveTemplate, newTemplate, activeTemplate, updateTemplateName, saveTemplate, removeTemplate }) {
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    const options = templateList.map((template) => (
      <option key={template._id} value={template._id}>{template.name}</option>
    ));
    setSelectOptions(options);
  }, [templateList]);

  return (
    <div>
      <div className='template-options-container'>
        <select name='template-select' id='template-select' onChange={updateActiveTemplate}>
          <option value='none'>Select</option>
          {selectOptions}
        </select>
        <button id='new-template-button' onClick={newTemplate}>New</button>
        <button id="save-template-button" onClick={saveTemplate}>Save</button>
        <button id="delete-template-button" onClick={removeTemplate}>Delete</button>
      </div>
      <div className='template-options-container'>
        <label htmlFor="template-name-input">Name:</label>
        <input type="text" id="template-name-input" value={activeTemplate && activeTemplate.name} onChange={updateTemplateName} />
      </div>
    </div>
  );
}

export default TemplateOptions;
import React from 'react';

function TemplateOptions({ currentPdf, pdfList, uploadPdf, updatePdfName, savePdf, updateCurrentPdf }) {
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    }).then((base64) => {
      uploadPdf(base64);
    });
  };

  return (
    <div>
      <div className='template-options-container'>
        <select name='template-select' id='template-select' onChange={updateCurrentPdf}>
          <option value='none'>Select</option>
          {pdfList.map((pdf) => (
            <option value={pdf._id} key={pdf._id}>
              {pdf.name}
            </option>
          ))}
        </select>
        <button id='new-template-button'>
          Upload New
          <input
            type='file'
            accept='.pdf'
            id='new-template-input'
            onChange={handleFileChange}
          />
        </button>
        <button id="save-template-button" onClick={savePdf}>Save</button>
        {/* <button id="delete-template-button">Delete</button> */}
      </div>
      <div className='template-options-container'>
        <label htmlFor="template-name-input">Name:</label>
        <input type="text" id="template-name-input" value={currentPdf && currentPdf.name} onChange={updatePdfName}/>
      </div>
    </div>
  );
}

export default TemplateOptions;